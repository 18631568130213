/* Box Model Hack */
* {
    box-sizing: border-box;
    font-size: 16px;
  }
  
  /* Clear fix hack */
  .clearfix:after {
       content: ".";
       display: block;
       clear: both;
       visibility: hidden;
       line-height: 0;
       height: 0;
  }
  
  .clear {
      clear: both;
  }
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: lightgray;
}
.headLine{
  font-size: 30px;
}
#main{
  padding: 0 7.5%;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}
  
#splashImg {
    height: 300px;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    size: contain;
}
.wot{
  border: 5px solid #fef200;
  border-radius: 10px;
  margin: 10px;
  padding: 0 10px;
}