button{
    font-weight: 500;
    font-size: 20px;
    padding: 5px;
    margin: 10px;
    border-radius: 10px;
}
button:hover{
    opacity: 50%;
}
@media (orientation: portrait){
    button{
        font-size: 16px;
        margin: 10px 0px 10px 3px;
    }
    .buttonSection{
        padding: 0;
        margin: 0;
    }
}