@counter-style thumbs {
    system: cyclic;
    symbols: "\1F44D";
    suffix: " ";
  }

.filterUL{
    border-radius: 5px;
}
.filterLI{
    /*list-style-image: url('../img/flag-checkered.svg');
    list-style-position: outside;*/
    list-style: none;
    margin: 0;
}
.filterImage{
    height: 20px;
    border-radius: 3px; 
}
strong{
    font-size: 18px;
}

@media (orientation: portrait){
    .filterLI{
        font-size: 16px;
        margin-left: -30px;
    }
}