

#map_canvas {
    height: 70vh;
    width: 90vw;
}

h3{
    color: black
}
#angry_main{
    margin: 0 5vw;
}
.mainInfo{
    font-size: 14px;
    display: flex;
    flex-direction: column;
}
.marker{
    border: 5px solid black;
}
.markers{
    border: 5px solid black;
}
.mapUL{
    text-decoration: none;
    max-width: 70vw;
}
.mapLI{
    font-size: 14px;
    font-weight:300;
    list-style: none;
}
.title{
    margin: 0;
    max-width: 70vw;
}
