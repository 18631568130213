

@media (orientation: landscape){
    .main{
        padding: 10px 7.5% 15px 7.5%;
        display: flex;
        justify-content: space-around;
    }
  }
@media (orientation: portrait){
    .main{
        padding: 10px 7.5% 15px 7.5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .main span{
        padding: 3px;
    }
}

a{
    font-weight: 300;
    color: black;
    text-decoration: none;
}